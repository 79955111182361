import "./styles.css";
import usePartySocket from "partysocket/react";
import { createRoot } from "react-dom/client";
import { useState, useEffect } from "react";
import { ChromePicker } from "react-color";
import chroma from "chroma-js";

function App() {
  const [color, setColor] = useState("#FFFFFF"); // color from server
  const [userColor, setUserColor] = useState("#E7E8EF"); // user selected color
  const [score, setScore] = useState(null);
  const [gameCountdown, setGameCountdown] = useState(20000); // Initialize game countdown
  const [leaderboardCountdown, setLeaderboardCountdown] = useState(0); // Initialize leaderboard countdown

  const [hasSubmitted, setHasSubmitted] = useState(false); // track if user has submitted
  const [leaderboard, setLeaderboard] = useState([]);
  const [playerName, setPlayerName] = useState("");
  const [count, setCount] = useState(0);
  const [accuracy, setAccuracy] = useState(null); // Accuracy based on color difference
  const [timeTaken, setTimeTaken] = useState(null); // Time taken to submit color
   const [dailyLeaderboard, setDailyLeaderboard] = useState([]);
  const [allTimeLeaderboard, setAllTimeLeaderboard] = useState([]);

    useEffect(() => {
        // Check localStorage for a stored name
        const storedName = localStorage.getItem("playerName");
        if (storedName) {
          setPlayerName(storedName);
        }
      }, []);

const loadLeaderboards = async () => {
  const dailyData = await fetchLeaderboard('daily');
  const allTimeData = await fetchLeaderboard('all-time');
  setDailyLeaderboard(dailyData);
  setAllTimeLeaderboard(allTimeData);
};

useEffect(() => {
  loadLeaderboards();
}, []);

  const ws = usePartySocket({
    room: "example-room",
    onMessage(evt) {
      const message = evt.data;
      if (message.startsWith("color: ")) {
        setColor(message.split("color: ")[1]);
        setScore(null);
        setHasSubmitted(false);
        // No need to manually set countdowns here, server manages them
      } else if (message.startsWith("gameCountdown: ")) {
        setGameCountdown(parseInt(message.split("gameCountdown: ")[1], 10));
      } else if (message.startsWith("leaderboardCountdown: ")) {
        setLeaderboardCountdown(parseInt(message.split("leaderboardCountdown: ")[1], 10));
      } else if (message.startsWith("leaderboard: ")) {
        setLeaderboard(JSON.parse(message.split("leaderboard: ")[1]));
      }
    },
  });
  const handleColorChange = (color, event) => {
    setUserColor(color.hex);
  };


    const durableObjectName = 'COUNTER_COLORGAMES'; // Replace with the actual name of your Durable Object
    const fetchCount = async () => {
        try {
            const response = await fetch(`https://ts-gen-count.adam-f8f.workers.dev/?name=${durableObjectName}`);
            const data = await response.text();
            setCount(data);
        } catch (error) {
            console.error('Error fetching count:', error);
        }
    };

    const handleIncrement = async () => {
        try {
            await fetch(`https://ts-gen-count.adam-f8f.workers.dev/increment?name=${durableObjectName}`, {
                method: 'POST',
            });
            fetchCount(); // Update count after increment
        } catch (error) {
            console.error('Error incrementing count:', error);
        }
    };


const handleSubmit = async () => {
  if (!hasSubmitted && gameCountdown > 0) {
    let name = playerName;
    if (!name) {
      name = prompt("Please enter your name:");
      if (name) {
        setPlayerName(name);
        localStorage.setItem("playerName", name);
      } else {
        return; // Exit if no name is entered
      }
    }

    const deltaE = chroma.deltaE(color, userColor);
    const timeFactor = gameCountdown / 20000; // Normalize time factor (0 to 1)

    // Calculate accuracy and time taken
    const accuracy = 100 - deltaE; // Assuming 100 - deltaE represents accuracy
    const timeTaken = (20000 - gameCountdown) / 1000; // Time taken in seconds

    // Adjust these weights as necessary to balance difficulty
    const accuracyWeight = 75; // Weight for color accuracy
    const timeWeight = 25;    // Weight for time factor
    const accuracyScore = accuracy * (accuracyWeight / 100);
    const timeScore = timeFactor * (timeWeight / 100);
    const totalScore = Math.max(0, Math.min(100, accuracyScore + timeScore)); // Clamp score between 0 and 100

    setScore(totalScore);
    setAccuracy(accuracy); // Set accuracy
    setTimeTaken(timeTaken); // Set time taken
    setHasSubmitted(true);

    // Send score to WebSocket for current game leaderboard
    ws.send(`score: ${JSON.stringify({ name: name, score: totalScore })}`);

    // Prepare the data to be sent to Cloudflare Worker
    const scoreData = {
      user: name,
      score: totalScore,
      accuracy,
      selectedColor: userColor,
      randomColor: color,
      timeTaken
    };

    // Send score data to Cloudflare Worker for daily and all-time leaderboards
    try {
      await fetch('https://colormatch-partykit.adam-f8f.workers.dev/submit-score', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(scoreData),
      });
      await loadLeaderboards();
    } catch (error) {
      console.error('Error submitting score to Cloudflare Worker:', error);
    }
  }
};

async function fetchLeaderboard(category) {
  const workerUrl = `https://colormatch-partykit.adam-f8f.workers.dev/get-scores?category=${category}`;
  try {
    const response = await fetch(workerUrl);
    const leaderboardData = await response.json();
    return leaderboardData;
  } catch (error) {
    console.error('Error fetching leaderboard:', error);
    return [];
  }
}

  const formattedGameCountdown = (gameCountdown / 1000).toFixed(2);
  const formattedLeaderboardCountdown = (leaderboardCountdown / 1000).toFixed(0);

  return (
    <div style={{ backgroundColor: userColor, minHeight: '100dvh', color: chroma.contrast(userColor, 'white') > 4.5? 'white' : 'black' }}>
      <div style={{ transition: 'color .2s ease, background-color .2s ease', width: "100%", height: "256px", backgroundColor: color, color: chroma.contrast(color, 'white') > 4 ? 'white' : 'black' }}>
<div>
  {gameCountdown > 0 ? 
        <div style={{textAlign: 'center', paddingTop: '8px'}}><time style={{ whiteSpace: 'nowrap', width: '96px', fontSize: '12px', fontFamily: 'monospace', display: 'inline-block', padding: '4px 12px'}}>{formattedGameCountdown} <small>seconds</small></time></div> : 
        (leaderboardCountdown > 0 ? <div style={{textAlign: 'center', paddingTop: '8px'}}><time style={{ fontSize: '12px', color: 'white', fontFamily: 'monospace', background: 'rgba(0,0,0,.7)', borderRadius: '9999px', display: 'inline-block', padding: '4px 12px'}}><small>Next game in</small> {formattedLeaderboardCountdown}</time></div> : null)}

{score !== null && (
        <div style={{display: 'flex', justifyContent: 'center', opacity: .8 }}>
        <div>
          <dl style={{marginBottom: 0}}>
              <dt style={{fontSize: '12px', margin: 0}}>Your Score</dt> 
              <dd style={{margin: 0, padding: 0, fontSize: '64px', fontWeight: 700, lineHeight: 1 }}>{score.toFixed(2)}</dd>
          </dl>
          <div style={{ display: 'flex', gap: '32px' }}>
          <dl>
              <dt style={{fontSize: '12px', margin: 0}}>Accuracy</dt> 
              <dd style={{margin: 0, padding: 0, fontSize: '16px'}}>{accuracy.toFixed(2)}%</dd>
          </dl>
          <dl>
              <dt style={{fontSize: '12px', margin: 0}}>Time</dt> 
              <dd style={{margin: 0, padding: 0, fontSize: '16px'}}>{timeTaken.toFixed(2)} seconds</dd>
          </dl>
          </div>
          </div>
        </div>
      )}
</div>
     </div>
      <div
        style={{
          width: "100%",
          height: "160px",
          backgroundColor: userColor,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ maxWidth: '480px' }}>
            <ChromePicker disableAlpha={true} width='300px' color={userColor} onChange={handleColorChange} />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '48px', marginBottom: '24px' }}>
          <button className='animated-button' onClick={handleSubmit} disabled={hasSubmitted}>
<span></span>
<span></span>
<span></span>
<span></span>
          Submit Color</button>
      </div>
      <section id='scores' style={{ display: 'flex', padding: '0 16px', margin: '0 auto', maxWidth: '960px', justifyContent: 'space-between', gap: '32px' }}>
 <section className='w-25 dn db-ns' style={{ opacity: .75 }}>
        <h4 style={{fontSize:'12px'}}>Today</h4>
        <ol style={{padding: 0}}>
          {dailyLeaderboard.slice(0,10).map((entry, index) => (
            <li key={entry.user} style={{ lineHeight: 1.5, borderBottom: '1px solid', display: 'flex', gap: '8px', fontSize: '10px'}}>
                <span style={{fontFamily: 'monospace'}}>{index+1}. </span>
                <span>{entry.user} </span>
                <code style={{marginLeft: 'auto'}}>{entry.score.toFixed(2)}</code>
            </li>
          ))}
        </ol>
 </section>
 <section className='w-100 w-50-ns'>
      <h4 style={{ textAlign: 'center', fontSize: '12px' }}>Game</h4>
      <ol style={{ padding: 0, maxWidth: '320px', listStyleType: 'none', margin: '0 auto' }}>
        {leaderboard.map((entry, index) => (
          <li key={index}
          style={{
                display: 'flex',
                fontSize: '12px',
                gap: '16px',
              }}
          >
              <span>{index+1}</span>
              <strong>{entry.name}</strong>
              <code style={{ marginLeft: 'auto' }}>{entry.score.toFixed(3)}</code>
          </li>
        ))}
      </ol>
  </section>
 <section className='w-25 dn db-ns' style={{ opacity: .75 }}>
        <h4 style={{fontSize:'12px'}}>All-Time</h4>
        <ol style={{padding: 0}}>
          {allTimeLeaderboard.slice(0,10).map((entry, index) => (
            <li key={entry.score} style={{ lineHeight: 1.5, borderBottom: '1px solid', display: 'flex', gap: '8px', fontSize: '10px'}}>
                <span style={{fontFamily: 'monospace'}}>{index+1}. </span>
                <span>{entry.user} </span>
                <code style={{marginLeft: 'auto'}}>{entry.score.toFixed(2)}</code>
            </li>
          ))}
        </ol>
      </section>
      </section>
  
    </div>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
